import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"custom-form scrollable-dialog",attrs:{"color":"#95a2b710"}},[_c(VCardText,{staticClass:"dialog__inputs"},[_vm._t("form")],2),(!_vm.hideActions)?_c(VCardActions,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ esc:['esc'] }),expression:"{ esc:['esc'] }"}],staticClass:"mr-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.closeDialog()}},nativeOn:{"shortkey":function($event){return _vm.closeDialog()}}},[_vm._v(" Anuluj ")]),_vm._t("submit")],2)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }