<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="finishCourseForm"
        ref="finishCourseForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitFinishCourseForm"
      >
        <v-row>
          <v-col cols="12">
            <DateWithTime
              label="Czas zakończenia kursu"
              :minutes-step="1"
              :date-with-time="confirmation.realTransportTime"
              @changeTime="confirmation.realTransportTime = $event"
            />
          </v-col>

          <v-col
            v-if="licencePlateFieldVisibilityConditions"
            cols="12"
          >
            <v-text-field
              outlined
              label="Numer rejestracyjny środka transportu"
              v-model="confirmation.licensePlate"
              :rules="[rules.required]"
              placeholder="Wpisz dodatkowe informacje"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="finishCourseForm"
      >
        Zakończ kurs
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTime from './Inputs/DateWithTime.vue'
import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { Course } from '../../models'

export default {
  components: {
    DialogFormWrapper,
    DateWithTime,
  },
  data () {
    return {
      isFormValid: true,
      rules,
      confirmation: {
        id: null,
        realTransportTime: DateTime.now().toISO(),
        licensePlate: undefined,
      }
    }
  },
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.bdoCard.isProcessing,
    }),
    course () {
      return new Course(this.item)
    },
    bdoCard () {
      const { bdoCard } = this.course.swapCourse || this.course
      return bdoCard
    },
    licencePlateFieldVisibilityConditions() {
      const { order: { client } } = this.course.swapCourse || this.course
      return this.bdoCard?.cardStatus === 'Zatwierdzona' && ['Wystawia KPO', 'Upoważnienie KPO'].includes(client.bdoType)
    }
  },
  mounted () {
    const { vehicleRegNumber, realTransportTime } = this.bdoCard || {}

    this.confirmation.id = this.course.id
    if (vehicleRegNumber) this.confirmation.licensePlate = vehicleRegNumber
    if (realTransportTime) this.confirmation.realTransportTime = realTransportTime
  },
  methods: {
    ...mapActions({
      finishCourse: 'course/finishCourse',
      closeDialog: 'layout/closeDialog',
    }),
    submitFinishCourseForm () {
      if (this.$refs.finishCourseForm.validate()) {
        this.finishCourse(this.confirmation)
          .then(() => { this.closeDialog() })
      }
    }
  }
}
</script>
